
$flow-button-bg: $white;
$flow-button-bg-hover: $blue;
$flow-button-text-color: #222;
$flow-button-text-color-hover: $white;
$flow-button-text-alignment: center;
$flow-button-border: 2px darken($flow-button-bg-hover, 5%) solid;
$flow-button-border-hover: 2px darken($flow-button-bg-hover, 10%) solid;
$flow-button-border-radius: 0 !important;
$flow-button-border-radius: 0 !important;
$flow-button-line-height: 55px;
$flow-button-padding: 0 2em;

.flow-btn {
  background-color: #FFF;
  color: $flow-button-text-color;
  text-align: $flow-button-text-alignment;
  border: $flow-button-border;
  border-radius: $flow-button-border-radius;
  line-height: $flow-button-line-height;
  padding: $flow-button-padding;
  margin-bottom: 1rem;
  font-weight: 600;

  &:active, &:focus, &:hover {
    box-shadow: none !important;
    color: $white;
    border-color: $flow-button-bg-hover;
    background-color: $flow-button-bg-hover;
  }

  .next-btn-wrap & {
    $next-btn-bg: $blue;
    $next-btn-bg-hover: darken($next-btn-bg, 10%);

    background: $next-btn-bg;
    border: 1px solid darken($next-btn-bg, 5%);;
    border-radius: 4px;
    color: #FFF !important;
    padding: 0.75em;
    margin-bottom: 16px;
    line-height: 1.5em;
    text-shadow: none;
    font-size: 20px;
    font-weight: 600;
    display: block;
    width: 100%;
    text-align: center;

    &:before {
      content: "";
      margin: 0;
    }

    &:after {
      font-family: 'FontAwesome';
      content: "\f101";
      font-size: 1.25em;
      margin-left: 0.25em;
      position: relative;
      top: 1px;
    }

    &:hover {
      background-color: $next-btn-bg-hover;
    }
  }
}
